<template>
  <Loading></Loading>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Loading from "@/components/Page/Liff/Shared/Loading";
import registerMixin from "@/mixins/liff/register";

export default {
  mixins: [registerMixin],
  components: {
    Loading,
  },
  computed: {
    ...mapGetters({
      from: "route/from",
      hasIntended: "route/hasIntended",
    }),
    intended() {
      if (this.$route.query.redirect_uri) {
        return this.$route.query.redirect_uri
      }

      if (this.$route.query.redirect_url) {
        return this.$route.query.redirect_url
      }

      if (!this.hasIntended) {
        return this.from?.fullPath
      }

      return null
    },
  },
  created() {
    if (this.intended) {
      this.SET_INTENDED(this.intended)
    }
    if (this.$route.query.redirect_action) {
      this.SET_REDIRECT_ACTION(this.$route.query.redirect_action)
    }
    this.SET_BUTTON_DISPLAY({
      redirect_button: this.$route.query.redirect_button ? this.$route.query.redirect_button == 1 : null,
      success_button: this.$route.query.success_button ? this.$route.query.success_button == 1 : null,
      go_to_button: this.$route.query.go_to_button ? this.$route.query.go_to_button == 1 : null,
    })
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapMutations({
      SET_INTENDED: "route/SET_INTENDED",
      SET_REDIRECT_ACTION: "route/SET_REDIRECT_ACTION",
      SET_BUTTON_DISPLAY: "route/SET_BUTTON_DISPLAY",
    }),
    async init() {
      let pathName = "LiffRegisterMember"

      if (this.getModuleConfig('terms_page.enable') ?? false) {
        pathName = 'LiffRegisterTerms'
      } else if (this.getModuleConfig('verify_page.enable') ?? false) {
        pathName = 'LiffRegisterVerifyForm'
      }

      this.$router.push({
        name: pathName,
        params: {
          orgCode: this.$route.params.orgCode,
        },
        query: this.$route.query,
      })
    }
  }
}
</script>
